import React, { Component } from "react"
import styled from "@emotion/styled"

import ShowColors from "../ShowColors.fr"
import Input from "../atoms/Input"
import { theme } from "../particles/theme"
import Select from "../atoms/Select"
import Information from "../organisms/information.fr"

const StyledMain = styled.div`
  margin-top: 5rem;

  @media (max-width: ${theme.breakpoints.tablet}) {
    margin-top: 0;
  }

  h2 {
    font-size: 3rem;
    text-transform: uppercase;
    color: ${theme.colors.bodyFont};
  }

  .sort-info {
    display: grid;
    grid-template-columns: 1fr 1fr 0.5fr;
    grid-column-gap: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    border-bottom: 1px solid black;

    @media (max-width: ${theme.breakpoints.tablet}) {
      display: block;

      section:not(:last-of-type) {
        margin-bottom: 2rem;
      }
    }
  }

  .display-sort {
    display: grid;
    grid-template-columns: 2fr 1fr;

    @media (max-width: ${theme.breakpoints.mobileMd}) {
      grid-template-columns: 1fr;
    }

    section {
      display: grid;
      span {
        display: inline-block;
        width: 50%;
        font-size: 2.5rem;
        font-weight: 700;
        align-self: center;
        text-transform: uppercase;
        color: ${theme.colors.homeHardware.primary};
        transition: outline 0.2s ease;
        cursor: pointer;

        @media (max-width: ${theme.breakpoints.desktop}) {
          width: 80%;
        }

        &:hover {
          color: ${theme.colors.homeHardware.hover};
        }

        @media (max-width: ${theme.breakpoints.mobileMd}) {
          width: 100%;
          margin-bottom: 2rem;
        }
      }
    }
  }

  select,
  input {
    font-size: 1.6rem;
    outline: none;
    background-color: rgb(248, 248, 248);
  }

  select {
    border: 1px solid rgb(166, 166, 166);
    border-radius: 5px;
    padding: 10px;
    height: 40px;
  }

  .unavailable {
    margin-top: 20px;
    font-weight: bold;
    color: #c8102e;
    grid-column: 1 / -1;
  }

  #overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.7);
    transition: all 500ms;
    visibility: hidden;
    opacity: 0;
  }

  .popup {
    margin: 45vh auto;
    padding: 20px;
    background: #fff;
    border-radius: 5px;
    width: 50%;
    max-width: 400px;
    position: relative;
  }
  .popup .close {
    position: absolute;
    top: 5px;
    right: 10px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: ${theme.colors.homeHardware.primary};
    border: none;
    background-color: transparent;
  }
  .popup .close:hover {
    color: ${theme.colors.homeHardware.hover};
  }
  .popup .content {
    overflow: auto;
  }
`

class MainPage extends Component {
  constructor(props) {
    super(props)

    this.props = props
    this.state = {
      manufacturer: "Afficher tout",
      osi: "",
      idhNum: "",
      idhmaxNum: "",
      sort: "osi",
      colorName: "Afficher tout"
    }
  }

   updateState = e => {
      this.updateManufacturer(e)
      this.updateColor(e)
   }

   updateManufacturer = e => {
     this.setState({ manufacturer: e.target.value })
     this.setState({ colorName: "Afficher tout"})
     if (e.target.value !== "Afficher tout") this.setState({ sort: "colorName" })
     else this.setState({sort: "osi"})
   }
   updateColor = e => {
     let colorInfo = this.props.manufacturers.find(
       manufacturer => manufacturer.name === e.target.value
     )
     this.setState({
       colorInfo: colorInfo,
     })
   }

  updateOsi = e => {
    this.setState({osi: e.target.value.replace(/\D/g, "")})
  }

  updateIdh = e => {
    this.setState({idhNum: e.target.value.replace(/\D/g, "")})
  }

  updateIdhmax = e => {
    this.setState({idhmaxNum: e.target.value.replace(/\D/g, "")})
  }

  updateSort = e => {
    this.setState({ sort: e.target.value })
  }

  updateColorName = e => {
    this.setState({ colorName: e.target.value })
  }

  resetState = () => {
    this.setState({
        manufacturer: "Afficher tout",
        colorInfo: null,
        sort: "osi",
        osi: "",
        idhNum: "",
        idhmaxNum: "",
        colorName: "Afficher tout"
    })
  }

  getManufacturers = () => {
    let options = [{ value: `Afficher tout`, label: `Afficher tout` }]

     this.props.manufacturers.forEach(manufacturer => {
        if (manufacturer.color_cross_refs.length > 0)
          options.push({ value: manufacturer.name, label: manufacturer.name })
     })

    return options
  }

  getManufacturerColors = () => {
    let options = [{ value: `Afficher tout`, label: `Afficher tout` }]
      this.props.manufacturers.find(manufacturer => manufacturer.name === this.state.manufacturer).color_cross_refs.forEach(color => {
        options.push({value: color.color_name, label: color.color_name})
      })
      return options
  }


  getSortingOptions = () => {
    let options = []

    if (this.state.manufacturer !== "Afficher tout")
      options.push({ value: "colorname", label: "Nom de la couleur" })

    options.push(
      { value: "osi", label: "Numéro LePage" },
      { value: "idhmax", label: "Numéro IDH QuadMax" },
      { value: "idh", label: "Numéro IDH QUAD" }
    )

    return options
  }

  render() {
    return (
      <StyledMain>
        <Information className="hide-mobile" />
        <h2>Trouver une couleur</h2>
        <div className="sort-info">
          <Input
            placeholder="003"
            onChange={this.updateOsi}
            value={this.state.osi}
            name="osi"
            label="Par numéro LePage:"
          />
          <Input
            placeholder="1868687"
            onChange={this.updateIdh}
            value={this.state.idhNum}
            name="quadIDH"
            label="Par numéro de pièce Quad:"
          />
          <Input
            placeholder="1868687"
            onChange={this.updateIdhmax}
            value={this.state.idhmaxNum}
            name="quadMaxIDH"
            label="Par numéro de pièce Quad Max:"
          />
          <Select
            options={this.getManufacturers()}
            value={this.state.manufacturer}
            onChange={this.updateState}
            label="Par fabricant:"
          />
          {this.state.manufacturer !== `Afficher tout` ?
             <Select
               options={this.getManufacturerColors()}
               value={this.state.colorName}
               onChange={this.updateColorName}
               label="Par nom de couleur:"
             /> : ""
          }
          <p className="unavailable">
            Les produits Quad Max surlignés en ROUGE sont temporairement indisponibles
          </p>
      </div>
      <section className="display-sort">
          <section>
            <span
              role="button"
              onClick={this.resetState}
              onKeyDown={this.resetState}
              tabIndex={0}
            >
              Réinitialiser la recherche
            </span>
          </section>
          <Select
            options={this.getSortingOptions()}
            value={this.state.sort}
            onChange={this.updateSort}
            label="Triez ces couleurs par:"
          />
        </section>
        <ShowColors
          colorInfo={this.state.colorInfo}
          osi={this.state.osi}
          idhNum={this.state.idhNum}
          idhmaxNum={this.state.idhmaxNum}
          sort={this.state.sort}
          manufacturer={this.state.manufacturer}
          colorName={this.state.colorName}
        />
        <div id="overlay">
          <div className="popup">
            <button onClick={() => {var overlay = document.getElementById('overlay'); overlay.style.visibility = 'hidden'; overlay.style.opacity = 0}} className="close">&times;</button>
            <p className="content">Contactez votre Home Hardware local pour les commandes spéciales.</p>
          </div>
        </div>

      </StyledMain>
    )
  }
}

export default MainPage
